// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: rgb(197, 2, 60);
  --ion-color-primary-rgb: 217, 2, 2;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #bf0202;
  --ion-color-primary-tint: #dd1b1b;

  /** secondary **/
  --ion-color-secondary: #ffffff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #c5023c;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ffffff;
  --ion-color-secondary-tint: #ffffff;

  --ion-color-tertiary: #000b8b;
  --ion-color-tertiary-rgb: 255, 112, 112;
  --ion-color-tertiary-contrast: white;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #000b8b;
  --ion-color-tertiary-tint: #000b8b;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #e9ebf1;
  --ion-color-light-rgb: 233, 235, 241;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #cdcfd4;
  --ion-color-light-tint: #ebedf2;

  /* Settings Fonts Size And Icon Size
      =======================================================
      =======================================================*/
  --fallback-color: red;
  --h1-font-size: 24px;
  --h2-font-size: 22px;
  --h3-font-size: 17px;
  --h4-font-size: 15px;
  --h5-font-size: 12px;
  --h6-font-size: 12px;
  --text-font-size: 14px;

  /* Settings Fonts Size Icon
      =======================================================
      =======================================================*/
  --icon-size-small: 17px;
  --icon-size-medium: 22px;
  --icon-size-large: 32px;

  /* Settings Color Background Our App
    =======================================================
    =======================================================*/
  --cs-background-primary: #fff;
  --cs-background-secondary: #f5f5f5;
  --cs-background-accent: rgb(197, 2, 60);
  --cs-background-dark: #7f8490;
  --cs-background-border: #c7c7cc;

  /* Settings Color Text Our App
    =======================================================
    =======================================================*/
  --cs-text-primary: #30384c;
  --cs-text-secondary: #7f8490;
  --cs-text-light: #ffffff;
  --cs-text-accent: rgb(197, 2, 60);

  /* Settings Size Avatar
    =======================================================
    =======================================================*/
  --size-avatar: 72px;

  /* Settings Size Thumbnail
    =======================================================
    =======================================================*/
  --size-thumbnail: 120px;
  --size-ion-small-thumbnail: 72px;

  /* Settings Border Radius
    =======================================================
    =======================================================*/
  --border-radius: 5px;

  /* Settings Color Icon
    =======================================================
    =======================================================*/
  --cs-icon-color-primary: rgb(197, 2, 60);
  --cs-icon-color-secondary: #30384c;
  --cs-icon-color-light: #fff;

}

// Oder Color Combination
// @import "themes-color/sunset-orange";
// @import "themes-color/mocha";
// @import "themes-color/seagull";
// @import "themes-color/turquoise";
// @import "themes-color/bull-shot";
// @import "themes-color/olive-drab";
// @import "themes-color/flush-orange";
// @import "themes-color/my-sin";
// @import "themes-color/pastel-green";
// @import "themes-color/emerald";

//////////////////////////////////////////////////
// Theme: Ionic 4 UI Theme/Template App - Material Design - Orange-Light
// Web Site: https://csform.com/
// Author: CSFORM
// Email support:  dev@csform.com
//////////////////////////////////////////////////

/* Setting APopOver
=======================================================
=======================================================*/
ion-popover {
  ion-backdrop {
    opacity: 0.5 !important;
  }

  .popover-wrapper {
    .popover-content {
      width: 80vw;
      max-width: 300px;
    }
  }
}

/* Setting Avatar
=======================================================
=======================================================*/
ion-avatar {
  height: var(--size-avatar);
  width: var(--size-avatar);
}

.ios {
  ion-avatar {
    margin: 8px 0;
  }
}

/* Setting Thumbnail
=======================================================
=======================================================*/
ion-thumbnail {
  --border-radius: 5px;
  height: var(--size-thumbnail);
  width: var(--size-thumbnail);
}

.ios {
  ion-thumbnail {
    margin: 8px 0;
  }
}

/* SETTINGS ION-HEADER
=======================================================
=======================================================*/

.header-md:after {
  background-image: none !important;
}

/* SETTINGS TOOLBAR
=======================================================
=======================================================*/
ion-toolbar {
  --background: var(--cs-background-primary, #fff);
  --color: var(--cs-text-accent, rgb(197, 2, 60));
  --border-color: transparent;

  [slot="start"] {
    margin-inline-end: 0;
  }

  .ios {
    ion-back-button,
    ion-menu-button {
      --color: var(--cs-text-accent, rgb(197, 2, 60));
    }
  }

  ion-title {
    font-weight: 400 !important;
    font-size: var(--h3-font-size, 17px) !important;
  }
}

ion-item {
  --ripple-color: transparent;
}

.margin-half {
  margin: 16px 8px 0;
}

.padding-half {
  padding: 8px;
}

/* INCLUDE FONTS
=======================================================
=======================================================*/
@import "fontello";
@import "rtl";
@import "animation";

body {
  --ion-font-family: "Comfortaa", cursive;
}

/* SETTINGS FONT SIZE
=======================================================
=======================================================*/

.text-size-xl {
  font-size: var(--h1-font-size, 24px) !important;
  line-height: 29px;
  letter-spacing: 1px;
}

.text-size-lg {
  font-size: var(--h2-font-size, 22px) !important;
  line-height: 27px;
  letter-spacing: 1px;
}

.text-size-md {
  font-size: var(--h3-font-size, 17px) !important;
  line-height: 22px;
  letter-spacing: 1px;
}

.text-size-xs {
  font-size: var(--h4-font-size, 15px) !important;
  line-height: 20px;
  letter-spacing: 1px;
}

.text-size-sm {
  font-size: var(--h5-font-size, 12px) !important;
  line-height: 18px;
  letter-spacing: 1px;
}

.text-size-p {
  font-size: var(--text-font-size, 14px) !important;
  line-height: 20px;
  letter-spacing: 1px;
}

.text-nowrap {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* SETTINGS FONT WEIGHT
=======================================================
=======================================================*/

.font-light {
  font-weight: 300 !important;
}

.font-regular {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-bold {
  font-weight: 700 !important;
}

/* SETTINGS COLOR
=======================================================
=======================================================*/

.text-color-primary {
  color: var(--cs-text-primary, #30384c) !important;
}

.text-color-secondary {
  color: var(--cs-text-secondary, #7f8490) !important;
}

.text-color-accent {
  color: var(--cs-text-accent, rgb(197, 2, 60)) !important;
}

.text-color-light {
  color: var(--cs-text-light, #ffffff) !important;
}

/* SETTINGS COLOR BACKGROUND OUR APP
=======================================================
=======================================================*/
ion-page {
  background: var(--cs-background-primary, #fff);
}

ion-content {
  --background: var(--cs-background-primary, #fff);
}

ion-list {
  padding-top: 0 !important;
}

/* SETTINGS ITEM SLIDING
=======================================================
=======================================================*/

ion-item-option {
  --background: var(--cs-background-secondary, #f5f5f5);
  width: auto;
  --ion-color-primary-shade: transparent !important;
}

/* SETTINGS ITEM
=======================================================
=======================================================*/

ion-item {
  --border-color: var(--cs-background-border);
}

/* SETTINGS MAIN MENU
=======================================================
=======================================================*/

.ion-menu-toggle,
ion-menu-toggle {
  .icon-small {
    margin-right: 10px;
  }

  ion-label {
    overflow: hidden !important;

    h1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

/* SETTINGS COLOR AND SIZE ICON
=======================================================
=======================================================*/
.icon-small {
  color: var(--cs-icon-color-primary, rgb(197, 2, 60));
  font-size: var(--icon-size-small, 17px);
}

.icon-medium {
  color: var(--cs-icon-color-primary, rgb(197, 2, 60));
  font-size: var(--icon-size-medium, 22px);
}

.icon-large {
  color: var(--cs-icon-color-primary);
  font-size: var(--icon-size-large, 32px);
}

/* SETTINGS BUTTON SIZE
=======================================================
=======================================================*/

.default-button {
  --color: var(--cs-text-light, #ffffff);
  --background: var(--cs-background-accent, rgb(197, 2, 60)) !important;
  --padding-top: 12px;
  --padding-bottom: 12px;
  --padding-start: 20px;
  --padding-end: 20px;
  --border-radius: 5px;
  --box-shadow: none;
  font-size: var(--h4-font-size, 15px);
  font-weight: bold;
  height: auto;
  --background-hover: var(--cs-background-accent, rgb(197, 2, 60)) !important;
  --color-hover: var(--cs-text-light, #ffffff);
  --color-activated: var(--cs-text-light, #ffffff);
  --color-focused: var(--cs-text-light, #ffffff);
}

.button-icon {
  --background: var(--cs-background-primary, #ffffff) !important;
  --color: var(--cs-text-primary, #30384c) !important;
  --border-radius: 5px !important;
  --box-shadow: none;
  font-size: var(--h4-font-size, 15px) !important;
  font-weight: bold !important;
  --background-hover: var(--cs-background-primary, #ffffff) !important;
  --color-hover: var(--cs-text-primary, #30384c);
  --color-activated: var(--cs-text-primary, #30384c) !important;
  --color-focused: var(--cs-text-primary, #30384c) !important;

  [slot="start"] {
    margin-inline-end: 8px !important;
  }
}

.button-clear {
  --background: transparent !important;
  --color: var(--cs-text-primary, #30384c);
  --border-radius: 5px !important;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --padding-start: 10px;
  --padding-end: 10px;
  --box-shadow: none;
  font-size: var(--h3-font-size, 17px) !important;
  font-weight: bold !important;
  height: auto;
  --background-hover: transparent !important;
  --color-hover: var(--cs-text-primary, #30384c) !important;
  --color-activated: var(--cs-text-primary, #30384c) !important;
  --color-focused: var(--cs-text-primary, #30384c) !important;
}

.button-action-shit {
  --border-radius: 50% !important;
  min-height: 40px !important;
  min-width: 40px;
  --box-shadow: none !important;
  --background: transparent !important;
  --background-hover: transparent !important;

  ion-icon {
    font-size: var(--h1-font-size, 24px);
    color: var(--cs-icon-color-light);
  }
}

ion-button {
  --ripple-color: transparent !important;
  --color-focused: transparent;
  --background-activated: transparent !important;
}

ion-ripple-effect {
  color: transparent;
}

/* SETTINGS INPUT FIELD
=======================================================
=======================================================*/

.input {
  .icon-small {
    color: var(--cs-icon-color-primary, rgb(197, 2, 60));
  }
}

/* SETTINGS CHECK BOX
=======================================================
=======================================================*/

ion-checkbox {
  --background-checked: var(--cs-background-accent, rgb(197, 2, 60));
  --border-color-checked: var(--cs-background-accent, rgb(197, 2, 60));
  --border-color: var(--cs-background-dark, #7f8490);
  --border-radius: 4px;
  --checkmark-color: var(--cs-text-light, #ffffff);
}

/* SETTINGS TOOGLE
=======================================================
=======================================================*/

ion-toggle {
  --background: var(--cs-background-border, #c7c7cc);
  --background-checked: var(--cs-background-accent, rgb(197, 2, 60));
  --handle-background: var(--cs-background-primary, #fff);
  --handle-background-checked: var(--cs-background-primary, #fff);
  padding-inline-start: 4px;
  padding-inline-end: 4px;
}

/* SETTINGS RADIO
=======================================================
=======================================================*/

ion-radio {
  --color: var(--cs-background-dark, #7f8490);
  --color-checked: var(--cs-background-accent, rgb(197, 2, 60));
}

/* SETTINGS TABS
=======================================================
=======================================================*/

ion-tab-bar {
  --background: var(--cs-background-primary, #fff);
}

ion-tab-button {
  font-size: var(--h4-font-size, 15px);
  --color: var(--cs-text-secondary, #30384c);
  --color-selected: var(--cs-text-accent, rgb(197, 2, 60));
  --ripple-color: rgba(0, 0, 0, 0.1);

  .ripple-effect {
    display: none !important;
  }
}

/* SETTINGS RANGE
=======================================================
=======================================================*/

ion-range {
  --knob-background: var(--cs-background-accent, rgb(197, 2, 60));
  --bar-background-active: var(--cs-background-accent, rgb(197, 2, 60));
  --bar-background: var(--cs-background-border, #c7c7cc);
  --pin-background: var(--cs-background-accent, rgb(197, 2, 60));
}

/* SETTINGS ION-BADGE
=======================================================
=======================================================*/

ion-badge {
  --background: var(--cs-background-accent, rgb(197, 2, 60));
  --color: var(--cs-text-light, #ffffff);
  --padding-bottom: 5px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 5px;
  border-radius: 5px;
  font-weight: 400;
  font-size: var(--h4-font-size, 15px);
}

/* SETTINGS SEARCHBAR
=======================================================
=======================================================*/

ion-searchbar {
  --background: #f3f3f3 !important;
  --cancel-button-color: var(--cs-text-primary, #30384c) !important;
  --clear-button-color: var(--cs-text-primary, #30384c) !important;
  --color: var(--cs-text-primary, #30384c) !important;
  --icon-color: var(--cs-text-primary, #30384c) !important;
  --placeholder-color: var(--cs-text-primary, #30384c) !important;

  .searchbar-input {
    border-radius: 10px !important;
    box-shadow: none !important;
  }
}

/* SETTINGS SEGMENTS
=======================================================
=======================================================*/

ion-segment-button {
  --ripple-color: transparent;
  --color: var(--cs-text-primary, #30384c);
  --color-checked: var(--cs-text-accent, rgb(197, 2, 60));
}

/* SETTINGS SEGMENTS FOR IOS
=======================================================
=======================================================*/
// .ios {
//   ion-segment {
//     min-height: 65px !important;

//     ion-segment-button {
//       border-color: transparent !important;
//       border-color: transparent !important;
//       max-width: none !important;
//       min-width: 100px !important;
//     }
//   }

//   .segment-button-checked {
//     background: transparent !important;
//     border-bottom: 2px solid var(--cs-background-accent,rgb(197, 2, 60)) !important;
//   }
// }

/* SETTINGS NEW-CATEGORY
=======================================================
=======================================================*/

.new-category {
  background-color: var(--cs-background-accent, rgb(197, 2, 60));
  color: var(--cs-text-light, #ffffff);
  font-size: var(--h4-font-size, 15px);
  font-weight: 700;
  line-height: 17px;
  padding: 10px 20px;
}

/* SETTINGS WIZARD
=======================================================
=======================================================*/

.swiper-pagination-bullet-active {
  --bullet-background: var(--cs-background-accent, rgb(197, 2, 60)) !important;
  background: var(--bullet-background) !important;
}

cs-wizard-layout-1 {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: 50px !important;
  }
}

cs-wizard-layout-2 {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: 0 !important;
  }
}

cs-wizard-layout-3 {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: 50px !important;
  }
}

cs-wizard-layout-4 {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: 10px !important;
  }
}

cs-profile-layout-2 {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: -35px !important;
  }
}

cs-profile-layout-4 {
  .swiper-pagination.swiper-pagination-bullets {
    bottom: -25px !important;
  }
}

/* Default Overview Ionic Section
=======================================================
=======================================================*/

.background-size {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.background-white {
  background-color: var(--cs-background-primary, #fff);
}

[slot="end"] {
  margin-inline-start: 0;
}

[slot="start"] {
  margin-inline-end: 16px;
}

.ios {
  [slot="start"] {
    margin-inline-end: 16px;
  }
}

.border-radius {
  border-radius: var(--border-radius);
}

.no-box-shadow {
  box-shadow: none !important;
}

.transparent {
  --background: transparent !important;
}

.box-shadow {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2) !important;
  position: relative;
  z-index: 999;
}

.clearfix {
  display: block;
  clear: both;
}

/* Settings Spinner
================================================
================================================*/
ion-spinner {
  width: 40px;
  height: 40px;
  color: var(--cs-background-accent, rgb(197, 2, 60)) !important;
}

cs-spinner {
  &.svg-size {
    font-size: 45px;
    color: var(--cs-background-accent, rgb(197, 2, 60)) !important;
    stroke: var(--cs-background-accent, rgb(197, 2, 60)) !important;
    fill: var(--cs-background-accent, rgb(197, 2, 60)) !important;
  }
}

/* Settings Reorder Group
================================================
================================================*/
ion-reorder-group {
  ion-item {
    --inner-padding-end: 0 !important;
  }

  ion-reorder {
    display: flex !important;
    align-items: center !important;
    height: 100% !important;
    background: var(--cs-background-secondary, #f5f5f5) !important;
  }
}

/* Settings Picker
================================================
================================================*/
ion-picker {
  .picker-button {
    color: var(--cs-text-accent) !important;
  }

  .picker-opt {
    color: var(--cs-text-primary) !important;
  }

  .picker-opt-selected {
    color: var(--cs-text-accent) !important;
  }
}

/* Settings Select
================================================
================================================*/

ion-select {
  color: #000 !important; // var(--cs-text-accent) !important;
}

/* Settings Alert
================================================
================================================*/

ion-alert {
  .alert-wrapper {
    .alert-head {
      .alert-title {
        font-size: var(--h4-font-size, 15px) !important;
        font-weight: 300 !important;
        color: var(--cs-text-accent) !important;
      }

      .alert-sub-title {
        font-size: var(--h4-font-size, 15px) !important;
        font-weight: 300 !important;
        color: var(--cs-text-accent, rgb(197, 2, 60)) !important;
      }
    }

    .alert-message {
      color: var(--cs-text-primary, #30384c) !important;
      font-size: var(--h4-font-size, 15px);
    }

    .alert-radio-group {
      border-top: 1px solid var(--cs-background-border);
      border-bottom: 1px solid var(--cs-background-border);

      .alert-radio-label {
        font-size: var(--h4-font-size, 15px) !important;
        font-weight: 300 !important;
        color: var(--cs-text-primary) !important;
      }

      .alert-radio-inner {
        background-color: var(--cs-background-accent) !important;
      }

      .alert-radio-icon {
        border-color: var(--cs-background-dark, #7f8490) !important;
      }

      [aria-checked="true"] .alert-radio-icon {
        border-color: var(--cs-background-accent) !important;
      }

      [aria-checked="true"] .alert-radio-label {
        color: var(--cs-text-accent) !important;
      }
    }

    .alert-checkbox-group {
      border-top: 1px solid var(--cs-background-border);
      border-bottom: 1px solid var(--cs-background-border);

      .alert-checkbox-label {
        font-size: var(--h4-font-size, 15px) !important;
        font-weight: 300 !important;
        color: var(--cs-text-primary) !important;
      }

      .alert-checkbox-icon {
        border-color: var(--cs-background-dark, #7f8490) !important;
      }

      [aria-checked="true"] .alert-checkbox-label {
        color: var(--cs-text-accent) !important;
      }

      [aria-checked="true"] .alert-checkbox-icon {
        background: var(--cs-background-accent) !important;
        border-color: var(--cs-background-accent) !important;
      }
    }

    .alert-button-group {
      .alert-button {
        font-size: var(--h4-font-size, 15px) !important;
        font-weight: 400 !important;
        color: var(--cs-text-primary) !important;
      }
    }
  }
}

/* Settings Popover
================================================
================================================*/

ion-select-popover {
  ion-list {
    padding: 0 !important;

    ion-label {
      font-size: var(--h3-font-size, 17px) !important;
      font-weight: 300 !important;
      color: var(--cs-text-primary) !important;
    }
  }
}

ion-action-sheet {
  .action-sheet-button {
    color: var(--cs-text-secondary) !important;
    font-size: var(--h3-font-size, 17px) !important;
    font-weight: 400 !important;
  }

  .action-sheet-selected {
    color: var(--cs-text-primary) !important;
    font-weight: bold !important;
  }
}

/* Settings Alert Info
================================================
================================================*/
.info-dialog {
  text-align: center;

  .alert-head {
    text-align: center;
  }
}

/* Settings Alert Warning
================================================
================================================*/
.alert-warning {
  text-align: center;

  .alert-head {
    text-align: center;
  }

  .alert-button-group {
    padding: 8px 0 8px 8px !important;
  }

  button {
    width: 100%;
    margin-bottom: 20px;
    font-size: var(--h4-font-size, 15px);
    background: var(--cs-background-accent) !important;

    .alert-button-inner {
      text-align: center;
      color: var(--cs-text-light, #ffffff) !important;
      display: block;
    }
  }
}

/* Settings Alert Subscribe
================================================
================================================*/
.alert-subscribe {
  .alert-input:focus {
    border-bottom: 1px solid var(--cs-background-accent);
  }
}

/* Settings Alert Dialog
================================================
================================================*/
.basic-dialog {
  .alert-input:focus {
    border-bottom: 1px solid var(--cs-background-accent);
  }
}
