/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
/* */
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css";

@import "theme/apps.scss";
@import "theme/fonts.scss";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';


strong {
  font-family: inherit;
}

em {
  font-family: inherit;
}

u {
  font-family: inherit;
}

*:focus {
  outline: none;
}

.bg-transparent {
  background: transparent !important;
}

.e-split-btn-wrapper {
  button {
    background: white;
  }
}

.e-daterangepicker {
  button.e-active {
    background: var(--ion-color-primary) !important;
  }

  .e-start-btn {
    border-color: var(--ion-color-primary) !important;
  }

  .e-end-btn {
    border-color: var(--ion-color-primary) !important;
  }

  .e-selected span.e-day {
    background-color: var(--ion-color-primary) !important;
    border-color: var(--ion-color-primary) !important;
  }

  .e-apply {
    color: var(--ion-color-primary) !important;
  }

  .e-range-header {
    display: none !important;
  }

  .e-range-hover {
    background-color: #f18fac !important;

    span {
      background: #f18fac !important;
      color: white !important;
    }
  }
}

ion-modal.popup ion-backdrop {
  visibility: visible !important;
  z-index: 0;
}

ion-modal.popup-full-fiches {
  --top: 140px;
  --position: absolute;
}

ion-modal.popup-collab {
  --top: 7%;
  --width: 90%;
  --height: 87%;
  --position: absolute;
  @media screen and (max-width: 600px) {
    --top: 0;
    --width: 100%;
    --height: 100%;
    --position: absolute;
  }

  .vertical-scroll {
    height: calc(86% - 44px);
  }
}

ion-modal.popup-service {
  --top: 7%;
  --width: 90%;
  --height: 87%;
  --position: absolute;
  @media screen and (max-width: 600px) {
    --top: 0;
    --width: 100%;
    --height: 100%;
    --position: absolute;
  }
}

ion-modal.popup {
  --top: 15%;
  --width: 90%;
  --height: max(200px, 60vh);
  --position: absolute;

  .vertical-scroll {
    --height: 316px;
  }
}

ion-modal.popup-storage {
  --top: 7%;
  --width: 90%;
  --height: 87%;
  --position: absolute;
  @media screen and (max-width: 600px) {
    --top: 0;
    --width: 100%;
    --height: 100%;
    --position: absolute;
  }
}

ion-modal.popup-app {
  --top: 7%;
  --width: 90%;
  --height: 87%;
  --position: absolute;

  &.more-height {
    --top: 5% !important;
    --height: 90%;
  }
}

ion-col.support-tab {
  ion-header {
    display: none !important;
  }
}

ion-modal.support-modal,
ion-col.support-tab {
  .toolbar-1 {
    .toolbar-title {
      display: none !important;
    }

    ion-buttons {
      img,
      ion-button:not(:last-child) {
        display: none !important;
      }
    }
  }

  .supports-page__header,
  .toolbar-2,
  .toolbar-3,
  .toolbar-4,
  ion-footer {
    display: none !important;
  }
}

ion-modal.popup-app-height {
  --top: 10%;
  --width: 70%;
  --height: 80%;
  --position: absolute;
  @media screen and (max-width: 600px) {
    --top: 0;
    --width: 100%;
    --height: 100%;
    --position: absolute;
  }
}

ion-modal.popup-alert {
  --top: 15%;
  --width: 90%;
  --max-width: 400px;
  --height: 210px;
  --position: absolute;

  ion-backdrop {
    --backdrop-opacity: var(--ion-color-opacity, 0.4);
  }
}

ion-modal.popup.more-height-sms {
  --height: 80% !important;
  --max-height: 600px !important;

  .vertical-scroll {
    max-height: 372px !important;
  }
}

ion-modal.popup.more-height {
  --height: 80% !important;
  --max-height: 550px !important;

  .vertical-scroll {
    height: 80% !important;
    max-height: 516px !important;
  }
}

ion-modal.full-modal {
  --width: 100%;
  --height: 100%;
  --height: var(--windowInnerHeight);
  --border-radius: 0;
}

ion-modal.product-price {
  --width: 450px;
  --height: fit-content;
  --max-height: 100vh;
  --padding: 20px 40px;
}

.e-quick-popup-wrapper.e-device {
  display: none !important;
}

.no-caret {
  .e-search-icon {
    display: none !important;
    border: 0px;
  }
}

.searchbar {
  position: relative;
  background-color: #f3f3f3 !important;
  border-radius: 10px !important;
  border: 1px solid #ccc !important;

  ion-icon {
    position: absolute;
    left: 20px;
    top: 8px;
    color: #30384c !important;
  }

  span {
    border: none !important;

    input {
      line-height: 1rem !important;
      color: #30384c !important;
      padding-left: 49px !important;
      font-size: 1rem !important;
      border: none !important;
    }

    input:focus {
      outline: none !important;
    }

    .e-search-icon {
      display: none !important;
    }
  }

  .e-input-group .e-clear-icon,
  .e-input-group.e-control-wrapper .e-clear-icon {
    padding-right: 10px !important;
  }
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::after {
  background: transparent !important;
}

#customer-combo input {
  text-align: left !important;
  border-block-end: 1px solid rgb(197, 2, 60);
}

#customer-combo input:focus {
  text-align: left !important;
  border-block-end: 1px solid rgb(251, 203, 9);
}

app-calendario {
  .e-input-group.e-control-wrapper.e-date-wrapper.e-valid-input.e-non-edit {
    border: none !important;
    font-size: 15px;
  }
}

.btn-primary {
  background-color: var(--ion-color-primary) !important;
  border-radius: 8px !important;
  margin-left: auto !important;
  margin-right: auto !important;

  span {
    color: white !important;
  }
}

.d-none {
  display: none !important;
}

.segment-button-checked {
  border: 0 !important;
}

.custom-alert {
  .alert-wrapper {
    min-width: 180px;

    .alert-message {
      text-align: center !important;
    }
  }
}

ejs-combobox input {
  text-align: left !important;
}

.show-close .e-clear-icon.e-clear-icon-hide {
  display: block !important;
  margin-top: 3px;
}

ion-calendar-modal ion-buttons ion-button {
  --color: white !important;
}

.e-time-slots span {
  font-size: 14px !important;
  font-weight: bold !important;
}

//.ion-activated {
//  background: none!important;
//}
app-appuntamento-form {
  ejs-dropdownlist,
  ejs-datetimepicker span.e-control-wrapper,
  ejs-combobox,
  .control-wrapper,
  ion-textarea,
  ion-datetime,
  ion-input {
    background: var(--cs-background-primary) !important;
    padding: 4px 6px !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2) !important;
    border-radius: 5px !important;
    border: 1px solid #e2dfdf !important;
    // margin: 5px 16px 10px 16px !important;
    margin: 5px 0px !important;
    // width: calc(100% - 32px) !important;
    width: 100% !important;
  }

  ion-datetime,
  ion-input {
    height: 35px;
    padding-top: 8px !important;
  }

  //background: transparent!important;
  //box-shadow: none!important;
  //border: none!important;
}

.e-appointment {
  border-radius: 6px !important;

  &.e-appointment-note-parent {
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);

    .e-appointment-note {
      color: #000;
    }
  }
}

div.e-quick-popup-wrapper.e-lib.e-popup.e-control.e-popup-open {
  display: none !important;
}

/* Settings Accordion "OPEN" */
.item-accordion {
  background: var(--cs-background-secondary);
  -moz-transition: all 400ms;
  -o-transition: all 400ms;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  height: 0;
  // display: block !important;
  overflow: hidden;

  &.active {
    -moz-transition: all 400ms;
    -o-transition: all 400ms;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    overflow: visible;
    height: fit-content;
  }
}

ngx-material-timepicker-content {
  header,
  span.active,
  .clock-face__clock-hand:after,
  span.clock-face__clock-hand {
    background-color: var(--ion-color-primary) !important;
  }

  button {
    color: var(--ion-color-primary) !important;
  }

  .clock-face__clock-hand_minute:before {
    border-color: var(--ion-color-primary) !important;
  }
}

ion-textarea textarea {
  overflow: auto !important;
}

.btn-transparent {
  --box-shadow: none;
  --background: transparent;
}

.e-schedule .e-vertical-view .e-work-cells:hover {
  background: #fdd1d1 !important;
}

@media screen and (min-width: 912px) {
  .alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
    max-width: 360px !important;
    width: 360px;
  }
}

.width-100 {
  width: 100%;
}

.version-button {
  background-color: rgb(197, 2, 60) !important;
  justify-content: left !important;
  margin-left: 20px !important;
  margin-bottom: 15px !important;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
  width: 100% !important;
  margin-right: 20px !important;

  .alert-button-inner {
    justify-content: center !important;
    color: #fff !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-decoration-none {
  text-decoration: none;
}

.scrollable-element {
  overflow: hidden;
  overflow-y: scroll;
}

ion-modal .ion-page:not(ion-nav .ion-page) {
  justify-content: start !important;

  .scrollable-element {
    height: 100%;
  }
}

.margin {
  &__right {
    &__medium {
      margin-right: 1.375rem;
    }
  }
}

.flex {
  display: flex;

  &__direction {
    &__column {
      flex-direction: column;
    }

    &__row {
      flex-direction: row;
    }
  }

  &__justify {
    &__content {
      &__center {
        justify-content: center;
      }

      &__end {
        justify-content: end;
      }
    }
  }

  &__align {
    &__items {
      &__center {
        align-items: center;
      }
    }
  }
}

.is-borderless {
  border: none !important;
}

// TODO
// Generate predefined helper classes for spacings: example: mt-2 = margin-top: 2px;
// Same for all alignments
.ml-14 {
  margin-left: 14px;
}

.background-transparent {
  background-color: transparent;
}

ion-toolbar {
  .e-date-wrapper {
    border: none !important;
    font-size: 16px !important;
  }

  .e-input-group-icon {
    &.e-date-icon {
      &.e-icons {
        display: none !important;
      }
    }
  }
}

ion-accordion-group {
  ion-accordion[data-color="upload"] {
    .ion-accordion-toggle-icon {
      color: #eb445a;
    }
  }

  ion-accordion[data-color="download"] {
    .ion-accordion-toggle-icon {
      color: #2dd36f;
    }
  }
}

.e-custom-style {
  span {
    border: none !important;
  }

  input {
    padding: 8px !important;
  }
}

.position-fixed {
  position: fixed;
}

.background-color-white {
  background-color: white;
}

.fullscreen {
  --width: 100% !important;
  --height: 100% !important;
}

ion-modal.modal-lg {
  --top: 7%;
  --width: 90%;
  --height: 87%;
  --position: absolute;
}

ion-badge.pedix {
  position: absolute;
  left: 12px;
  font-size: 10px;
  border-radius: 25px;
  margin: 0;
  padding: 0px;
  width: 16px;
  height: 16px;
  line-height: 17px;
  top: 14px;
}

@media only screen and (max-width: 600px) {
  .hide-mobile {
    display: none;
  }
}

.ion-display-flex {
  display: flex;
}

.item-interactive.app-invalid {
  --highlight-background: var(--highlight-color-invalid);
}

.app-invalid [slot="error"] {
  display: block;
}

.ion-select-bg {
  background-color: var(--cs-background-primary);
  border-radius: 5px;
  box-sizing: border-box;
  margin: 5px 0 5px 16px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

ion-modal.modal-default.show-modal:last-of-type {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.33) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}