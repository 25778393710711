/* SETTINGS RTL STYLE
=======================================================
=======================================================*/

html[dir=rtl] {

  cs-google-card-list-layout-3 {

    .new-category {
      right: unset !important;
      left: 0;
    }
  }
  /* SETTINGS INPUT FIELD
      =======================================================*/
  .input {
    ion-icon {
      left: 20px;
      right: unset !important;
    }
  }

  cs-register-layout-2 {
    .register-title {
      left: unset !important;
      right: 16px;
    }
  }
  /* SETTINGS REGISTER
      =======================================================*/
  cs-wizard-layout-1 {
    ion-card-content {
      margin-right: unset !important;
      margin-left: 20%;
    }

    ion-card {
      left: unset !important;
      right: 16px;
    }
  }
  /* SETTINGS COMMENTS WITH TIMELINE
      =======================================================*/
  cs-comment-layout-2 {
    ion-item {
      &:before {
        left: unset;
        right: 50px;
      }
    }
  }
  /* SETTINGS TABS
    =======================================================*/
  ion-tabs {
    app-tab5 {
      ion-list > ion-item::before {
        left: auto !important;
        right: 50px;
      }
    }
  }
  /* SETTINGS TIMELINE
    =======================================================*/
  cs-timeline-layout-1,
  cs-timeline-layout-3 {
    ion-card {
      &:after {
        right: -30px;
        left: unset;
      }

      &:before {
        right: -36px;
        left: unset;
      }
    }
  }

  cs-timeline-layout-2 {
    .item-thumbnail {
      &:after {
        right: -30px;
        left: unset;
      }

      &:before {
        right: -36px;
        left: unset;
      }
    }
  }
  /* SETTINGS ALERT
    =======================================================*/
  .alert-button-group {
    padding: 8px 8px 8px 0 !important;
  }
}
