// Comfortaa light
@font-face {
  font-family: Comfortaa;
  src: url(../assets/fonts/Comfortaa/Comfortaa-Light.ttf) format("truetype");
  font-style: normal;
  font-weight: 300;
}

// Comfortaa Regular
@font-face {
  font-family: Comfortaa;
  src: url(../assets/fonts/Comfortaa/Comfortaa-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
}

// Comfortaa Bold
@font-face {
  font-family: Comfortaa;
  src: url(../assets/fonts/Comfortaa/Comfortaa-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 700;
}

// CenturyGothic normal
@font-face {
  font-family: "Century Gothic";
  src: url(../assets/fonts/CenturyGothic/CenturyGothic.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
}

// CenturyGothic Bold
@font-face {
  font-family: "Century Gothic";
  src: url(../assets/fonts/CenturyGothic/CenturyGothic-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 700;
}

// CenturyGothic normal
@font-face {
  font-family: "Itc Avant Guade";
  src: url(../assets/fonts/ITCAvant/ITCAvantGardeStdMd.woff) format("truetype");
  font-style: normal;
  font-weight: 400;
}

// CenturyGothic Bold
@font-face {
  font-family: "Itc Avant Guade";
  src: url(../assets/fonts/ITCAvant/ITCAvantGardeStdBold.woff) format("truetype");
  font-style: normal;
  font-weight: 700;
}


@font-face {
  font-family: "Aspire";
  src: url(../assets/fonts/Aspire/Aspire.ttf) format("truetype");
  font-style: normal;
}


@font-face {
  font-family: "Change";
  src: url(../assets/fonts/Change/Change.otf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Aspira";
  src: url(../assets/fonts/Aspira/Aspira-Regular.otf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Copperplate";
  src: url(../assets/fonts/CopperPlate/OPTICopperplate.otf) format("truetype");
  font-style: normal;
}


@font-face {
  font-family: Montserrant;
  src: url(../assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Montserrant;
  src: url(../assets/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Montserrant;
  src: url(../assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: AvenirNextW1G;
  src: url(../assets/fonts/Avenir/AvenirNextW1G-Regular.otf) format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: AvenirNextW1G;
  src: url(../assets/fonts/Avenir/AvenirNextW1G-UltraLight.otf) format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: AvenirNextW1G;
  src: url(../assets/fonts/Avenir/AvenirNextW1G-Bold.otf) format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: TramCn;
  src: url(../assets/fonts/Tram/TramCn-Book.otf) format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: TramCn;
  src: url(../assets/fonts/Tram/TramCn-Thin.otf) format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: TramCn;
  src: url(../assets/fonts/Tram/TramCn-Bold.otf) format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: GoldieHand;
  src: url(../assets/fonts/Goldie/GoldieHand-Italic.otf) format("truetype");
}


// ##### Lato ####
@font-face {
  font-family: Lato;
  src: url(../assets/fonts/Lato/Lato-Thin.ttf) format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: Lato;
  src: url(../assets/fonts/Lato/Lato-ThinItalic.ttf) format("truetype");
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: Lato;
  src: url(../assets/fonts/Lato/Lato-Light.ttf) format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Lato;
  src: url(../assets/fonts/Lato/Lato-LightItalic.ttf) format("truetype");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: Lato;
  src: url(../assets/fonts/Lato/Lato-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Lato;
  src: url(../assets/fonts/Lato/Lato-Italic.ttf) format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: Lato;
  src: url(../assets/fonts/Lato/Lato-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Lato;
  src: url(../assets/fonts/Lato/Lato-BoldItalic.ttf) format("truetype");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: Lato;
  src: url(../assets/fonts/Lato/Lato-Black.ttf) format("truetype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: Lato;
  src: url(../assets/fonts/Lato/Lato-BlackItalic.ttf) format("truetype");
  font-style: italic;
  font-weight: 900;
}

// ######


// ##### Crimson Text ####

@font-face {
  font-family: Crimson Text;
  src: url(../assets/fonts/CrimsonText/CrimsonText-Roman.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Crimson Text;
  src: url(../assets/fonts/CrimsonText/CrimsonText-Italic.ttf) format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: Crimson Text;
  src: url(../assets/fonts/CrimsonText/CrimsonText-Semibold.ttf) format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Crimson Text;
  src: url(../assets/fonts/CrimsonText/CrimsonText-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 700;
}

// ######
