.beautymanagement {
  --color-primary: #fb5135;
  --color-secondary: rgba(0, 11, 67, 0.3);

  * {
    font-family: Montserrant, serif;
  }

  .text-item span {
    font-family: unset !important;
  }

  --ion-color-primary: var(--color-primary) !important;
  --ion-color-primary-shade: var(--color-primary) !important;
  --ion-color-primary-tint: var(--color-primary) !important;
  --cs-text-accent: var(--color-primary) !important;
  --cs-icon-color-primary: var(--color-primary) !important;
  --cs-background-accent: var(--color-primary) !important;
  --ion-color-secondary: var(--color-secondary) !important;
  --ion-color-tertiary: var(--color-primary) !important;
  --ion-font-family: "Montserrant", cursive !important;

  ion-list-header.tab-header {
    background: var(--color-primary);
    --indicator-color: var(--color-primary) !important;
  }
}

.kapp {
  --color-primary: #000000;
  --color-secondary: #ffa500;

  body:not(span, strong, em, u) {
    font-family: Montserrant, serif !important;
  }

  --ion-color-primary: var(--color-primary) !important;
  --cs-text-accent: var(--color-primary) !important;
  --cs-icon-color-primary: var(--color-primary) !important;
  --cs-background-accent: var(--color-primary) !important;
  --ion-color-secondary: var(--color-secondary);
  --ion-color-tertiary: var(--color-primary) !important;
  --ion-font-family: "Montserrant", cursive !important;

  ion-list-header.tab-header {
    background: var(--color-primary);
    --indicator-color: var(--color-primary) !important;
  }

  ion-title {
    color: var(--color-primary);
  }
}

.simplygest {
  --color-primary: #1F4E79;
  --color-secondary: #000000;

  body:not(span, strong, em, u) {
    font-family: Montserrant, serif !important;
  }

  --ion-color-primary: var(--color-primary) !important;
  --cs-text-accent: var(--color-primary) !important;
  --cs-icon-color-primary: var(--color-primary) !important;
  --cs-background-accent: var(--color-primary) !important;
  --ion-color-secondary: var(--color-secondary);
  --ion-color-tertiary: var(--color-primary) !important;
  --ion-font-family: "Montserrant", cursive !important;

  ion-list-header.tab-header {
    background: var(--color-primary);
    --indicator-color: var(--color-primary) !important;
  }

  ion-title {
    color: var(--color-primary);
  }
}

.gmoda {
  --color-primary: #000000;
  --color-secondary: #b6dee8;

  body:not(span, strong, em, u) {
    font-family: Montserrant, serif !important;
  }

  --ion-color-primary: var(--color-primary) !important;
  --cs-text-accent: var(--color-primary) !important;
  --cs-icon-color-primary: var(--color-primary) !important;
  --cs-background-accent: var(--color-primary) !important;
  --ion-color-secondary: var(--color-secondary);
  --ion-color-tertiary: var(--color-primary) !important;
  --ion-font-family: "Montserrant", cursive !important;

  ion-list-header.tab-header {
    background: var(--color-primary);
    --indicator-color: var(--color-primary) !important;
  }

  ion-title {
    color: var(--color-primary);
  }
}

//SALON MENU MERGE
.beauty {
  --color-primary: #3898d4;
  --color-secondary: white;

  body:not(span, strong, em, u) {
    font-family: Montserrant, serif !important;
  }

  --ion-color-primary: var(--color-primary) !important;
  --cs-text-accent: var(--color-primary) !important;
  --cs-icon-color-primary: var(--color-primary) !important;
  --cs-background-accent: var(--color-primary) !important;
  --ion-color-secondary: var(--color-secondary);
  --ion-color-tertiary: var(--color-primary) !important;
  --ion-font-family: "Montserrant", cursive !important;

  ion-list-header.tab-header {
    background: var(--color-primary);
    --indicator-color: var(--color-primary) !important;
  }

  ion-title {
    color: var(--color-primary);
  }
}

.beautymenu {
  --color-primary: #554294;
  --color-secondary: white;

  body:not(span, strong, em, u) {
    font-family: Montserrant, serif !important;
  }

  --ion-color-primary: var(--color-primary) !important;
  --cs-text-accent: var(--color-primary) !important;
  --cs-icon-color-primary: var(--color-primary) !important;
  --cs-background-accent: var(--color-primary) !important;
  --ion-color-secondary: var(--color-secondary);
  --ion-color-tertiary: var(--color-primary) !important;
  --ion-font-family: "Montserrant", cursive !important;

  ion-list-header.tab-header {
    background: var(--color-primary);
    --indicator-color: var(--color-primary) !important;
  }

  ion-title {
    color: var(--color-primary);
  }
}

.kmenu {
  --color-primary: #000000;
  --color-secondary: #ffa500;

  body:not(span, strong, em, u) {
    font-family: Montserrant, serif !important;
  }

  --ion-color-primary: var(--color-primary) !important;
  --cs-text-accent: var(--color-primary) !important;
  --cs-icon-color-primary: var(--color-primary) !important;
  --cs-background-accent: var(--color-primary) !important;
  --ion-color-secondary: var(--color-secondary);
  --ion-color-tertiary: var(--color-primary) !important;
  --ion-font-family: "Montserrant", cursive !important;

  ion-list-header.tab-header {
    background: var(--color-primary);
    --indicator-color: var(--color-primary) !important;
  }

  ion-title {
    color: var(--color-primary);
  }
}

.digitalpartnermenu {
  --color-primary: #000000;
  --color-secondary: white;

  body:not(span, strong, em, u) {
    font-family: Montserrant, serif !important;
  }

  --ion-color-primary: var(--color-primary) !important;
  --cs-text-accent: var(--color-primary) !important;
  --cs-icon-color-primary: var(--color-primary) !important;
  --cs-background-accent: var(--color-primary) !important;
  --ion-color-secondary: var(--color-secondary);
  --ion-color-tertiary: var(--color-primary) !important;
  --ion-font-family: "Montserrant", cursive !important;

  ion-list-header.tab-header {
    background: var(--color-primary);
    --indicator-color: var(--color-primary) !important;
  }

  ion-title {
    color: var(--color-primary);
  }
}

.bhub {
  --color-primary: #005B96;
  --color-secondary: #white;

  body:not(span, strong, em, u) {
    font-family: Montserrant, serif !important;
  }

  --ion-color-primary: var(--color-primary) !important;
  --cs-text-accent: var(--color-primary) !important;
  --cs-icon-color-primary: var(--color-primary) !important;
  --cs-background-accent: var(--color-primary) !important;
  --ion-color-secondary: var(--color-secondary);
  --ion-color-tertiary: var(--color-primary) !important;
  --ion-font-family: "Montserrant", cursive !important;

  ion-list-header.tab-header {
    background: var(--color-primary);
    --indicator-color: var(--color-primary) !important;
  }

  ion-title {
    color: var(--color-primary);
  }
}

.lisapp {
  --color-primary: #020D40;
  --color-secondary: #ffa500;

  body:not(span, strong, em, u) {
    font-family: Montserrant, serif !important;
  }

  --ion-color-primary: var(--color-primary) !important;
  --cs-text-accent: var(--color-primary) !important;
  --cs-icon-color-primary: var(--color-primary) !important;
  --cs-background-accent: var(--color-primary) !important;
  --ion-color-secondary: var(--color-secondary);
  --ion-color-tertiary: var(--color-primary) !important;
  --ion-font-family: "Montserrant", cursive !important;

  ion-list-header.tab-header {
    background: var(--color-primary);
    --indicator-color: var(--color-primary) !important;
  }

  ion-title {
    color: var(--color-primary);
  }
}

.ahs {
  --color-primary: #000000;
  --color-secondary: white;

  body:not(span, strong, em, u) {
    font-family: Montserrant, serif !important;
  }

  --ion-color-primary: var(--color-primary) !important;
  --cs-text-accent: var(--color-primary) !important;
  --cs-icon-color-primary: var(--color-primary) !important;
  --cs-background-accent: var(--color-primary) !important;
  --ion-color-secondary: var(--color-secondary);
  --ion-color-tertiary: var(--color-primary) !important;
  --ion-font-family: "Montserrant", cursive !important;

  ion-list-header.tab-header {
    background: var(--color-primary);
    --indicator-color: var(--color-primary) !important;
  }

  ion-title {
    color: var(--color-primary);
  }
}


.ex3community {
  --color-primary: #000000;
  --color-secondary: #white;

  body:not(span, strong, em, u) {
    font-family: Montserrant, serif !important;
  }

  --ion-color-primary: var(--color-primary) !important;
  --cs-text-accent: var(--color-primary) !important;
  --cs-icon-color-primary: var(--color-primary) !important;
  --cs-background-accent: var(--color-primary) !important;
  --ion-color-secondary: var(--color-secondary);
  --ion-color-tertiary: var(--color-primary) !important;
  --ion-font-family: "Montserrant", cursive !important;

  ion-list-header.tab-header {
    background: var(--color-primary);
    --indicator-color: var(--color-primary) !important;
  }

  ion-title {
    color: var(--color-primary);
  }
}
